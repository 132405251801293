<template>
  <RoleCreation />
</template>

<script>
import RoleCreation from "@/layouts/components/RolesManagement/RoleCreation.vue";

export default {
  components: {
    RoleCreation
  }
};
</script>
