<template>
  <div class="role-create-page">
    <div>
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        :finish-button-text="$t('login.Submit')"
        :next-button-text="nextBtn"
        :back-button-text="$t('alert.Previous')"
        @on-change="changeNxtBtn($event)"
        :class="
          isAddRole ? 'wizard-nav-pills-step-3  no-header-modal mb-3' : 'mb-3'
        "
        @on-complete="gotoRoleList"
      >
        <div class="">
          <div class="d-flex justify-content-between mb-1 mt-1">
            <h4 class="d-flex">{{ $t("roles_management.Roles") }}</h4>
            <b-button
              variant="outline-primary"
              class="v2-back"
              size="sm"
              :to="{ name: 'user-list', query: { tab: 'roles' } }"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">{{ $t("role.Back") }}</span>
            </b-button>
          </div>
        </div>
        <tab-content
          :title="$t('Role Name')"
          :before-change="validationForm"
          class="p-sm-1"
        >
          <validation-observer ref="unitRules" tag="form">
            <b-row>
              <b-col sm="6">
                <validation-provider
                  #default="validationContext"
                  name="role name"
                  :rules="{
                    required: true,
                    min: constants.MIN_ROLE_NAME,
                    max: constants.MAX_ROLE_NAME
                  }"
                >
                  <b-form-group :label="$t('role.RoleName')">
                    <b-form-input
                      :placeholder="$t('role.EnterRoleName')"
                      v-model="roleData.role_name"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{
                        validationContext.errors[0]
                          ? handleError(validationContext.errors[0])
                          : ""
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col sm="6">
                <validation-provider
                  #default="{ errors }"
                  :rules="{ required: true }"
                  name="Parent role"
                  class="mt-2"
                >
                  <b-form-group
                    :label="$t('role.ParentRole') + '*'"
                    class="role-list-input"
                  >
                    <!-- <v-select id="vue-select" v-model="selected" :roles="roles" /> -->

                    <v-select
                      id="vue-select"
                      :placeholder="$t('role.ParentRole')"
                      :options="roles"
                      :class="
                        errors.length == 0 && roleData.pid
                          ? 'is-valid'
                          : 'is-invalid'
                      "
                      v-model="roleData.pid"
                      :reduce="(option) => option.id"
                      @search="
                        (search, loading) => {
                          loading(true);
                          getRoles(search).then(() => loading(false));
                        }
                      "
                      label="role_name"
                    />

                    <small class="text-danger">{{
                      errors[0] ? handleError(errors[0]) : ""
                    }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- unit allocation tab -->
        <tab-content
          :title="$t('Role Permission')"
          :before-change="validateRolePermission"
        >
          <validation-observer ref="infoRules" tag="form">
            <Permissions
              :roleData="roleData"
              :allPermissions="
                (roleDataClone && roleDataClone.permissions) || allPermissions
              "
              :labels="labels"
            />
          </validation-observer>
        </tab-content>

        <!-- address  -->
        <tab-content :title="$t('role.Users')" class="no-header-modal">
          <b-row>
            <b-col sm="6">
              <div class="you-invite text-left you-c">
                {{ $t("user.AddUsers") }}
              </div>
              <b-form class="mt-1">
                <div class="add-email">
                  <b-form-group :label="$t('role.Users')">
                    <v-select
                      id="vue-select"
                      :placeholder="$t('role.SelectUsers')"
                      v-model="selectedUser"
                      :options="allUsers"
                      :reduce="(option) => option.id"
                      label="title"
                    />
                  </b-form-group>
                  <b-button
                    variant="primary"
                    size="sm"
                    class="btn-icon rounded-circle Add-circle"
                    @click="addSelectedUser"
                  >
                    <feather-icon icon="PlusIcon" size="18" />
                  </b-button>
                </div>
                <div class="role-scroll">
                  <span style="margin: 0px" class="role-padding-right">
                    {{ $t("role.AddUserInfo") }}
                  </span>
                  <div
                    class="media-list media-bordered btm"
                    :key="index"
                    v-for="(user, index) of invitedUsers"
                  >
                    <b-media right-align vertical-align="center">
                      <template #aside>
                        <b-button
                          variant="danger"
                          size="sm"
                          class="btn-icon rounded-circle"
                          @click="removeSelectedUser(index, user)"
                        >
                          <feather-icon icon="TrashIcon" size="18" /> </b-button
                      ></template>
                      <b-media class="align-item-center mt-1">
                        <template #aside>
                          <b-avatar
                            size="50"
                            :text="getInitials(user.title)"
                            variant="light-primary"
                          />
                        </template>
                        <h6 class="mb-0 user-mt-15 mt-0">{{ user.title }}</h6>
                        <!-- <small class="text-muted">{{ roleName }}</small> -->
                      </b-media>
                    </b-media>
                  </div>
                </div>
              </b-form>
            </b-col>
          </b-row>
          <b-overlay :show="show" no-wrap> </b-overlay>
        </tab-content>
      </form-wizard>
    </div>
    <b-overlay :show="show" no-wrap> </b-overlay>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { required } from "@validations";
import vSelect from "vue-select";

import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import formValidation from "@core/comp-functions/forms/form-validation";
import AccountService from "@/libs/api/account-service";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BMedia,
  BFormInvalidFeedback,
  BFormFile,
  BImg,
  BAvatar,
  BButton,
  BOverlay,
  BForm
} from "bootstrap-vue";
import image from "../../../assets/images/pages/avatar.svg";
import Permissions from "./Permissions.vue";
import constants from "@/utils/constants";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    vSelect,
    BOverlay,
    BMedia,
    BCol,
    BAvatar,
    BButton,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormFile,
    BImg,
    BForm,
    BCardCode,
    Permissions
  },
  data() {
    const { refFormObserver, getValidationState } = formValidation();
    return {
      required,
      roleData: {},
      allPermissions: {},
      labels: {},
      show: false,
      isAddRole: false,
      roles: [],
      roleDataClone: {},
      selectedUser: null,
      nextBtn: this.$t("roles_management.Next"),
      allUsers: [],
      invitedUsers: [],
      allUsersClone: [],
      getValidationState,
      roleName: ""
    };
  },
  async mounted() {
    this.$route.meta.breadcrumb = JSON.parse(
      JSON.stringify(constants.ROLE_CREATE_ROUTE_META_BREADCRUMB)
    );
    this.$route.meta.breadcrumb.push({
      text: "Breadcrumb_New Role",
      index: "New-role",
      active: true
    });
    // await this.getAllPermissions();
    await this.getAllUsers();
    await this.getRoles();

    if (this.$route.query.id) {
      await this.getRoleData();
    }
  },
  methods: {
    async getAllUsers() {
      try {
        let response = await new AccountService().getAccountUsers({});
        if (response && response.data) {
          this.allUsers = response.data.users.map((u) => {
            return {
              id: u.id,
              title: u.first_name + " " + u.last_name,
              value: u.id
            };
          });
          let loggedUser = localStorage.getItem("USER_PROFILE_DETAILS")
            ? JSON.parse(localStorage.getItem("USER_PROFILE_DETAILS")) || {}
            : {};
          if (
            loggedUser &&
            loggedUser.id &&
            this.allUsers &&
            this.allUsers.length
          ) {
            this.allUsers = this.allUsers.filter(
              (x) => x && x.id !== loggedUser.id
            );
          }
          this.allUsersClone = JSON.parse(JSON.stringify(this.allUsers));
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async changeNxtBtn(e) {
      if (e === 0) {
        this.nextBtn = this.$t("roles_management.SaveAndAddUsers");
        await this.getAllPermissions();
      } else {
        this.nextBtn = this.$t("roles_management.Next");
      }
    },
    removeSelectedUser(index, user) {
      this.invitedUsers.splice(index, 1);
      const selectedUser = this.allUsersClone.filter(
        (x) => x && x.id === user.id
      );
      if (selectedUser && selectedUser.length) {
        this.allUsers.push(selectedUser[0]);
      }
    },
    async getRolesDo(searchTerm) {
      try {
        let obj = {
          page: 1,
          page_size: 100,
          filters: []
        };
        if (searchTerm) {
          obj.filters.push({
            field: "all",
            value: searchTerm,
            operator: "ilike"
          });
        }

        let response = await new AccountService().getUserRolesRead(obj);

        if (response && response.data) {
          this.roles = response.data.list || [];
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async getRoles(searchTerm = "") {
      clearTimeout(this.getRolesTO);
      this.getRolesTO = setTimeout(() => {
        return this.getRolesDo(searchTerm);
      }, 500);
    },
    addSelectedUser() {
      const selectedUserObj = this.allUsers.filter(
        (x) => x && x.id === this.selectedUser
      );
      const invitedUserObj = this.invitedUsers.filter(
        (x) => x && x.id === this.selectedUser
      );
      if (invitedUserObj && invitedUserObj.length) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("role.UserAlreadyAddedTitle"),
            text: this.$t("role.UserAlreadyAddedMessage"),
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        return;
      }
      if (selectedUserObj && selectedUserObj.length > 0) {
        this.invitedUsers.push(selectedUserObj[0]);
        const addedUsers = this.invitedUsers.map((u) => u.id);
        if (addedUsers && addedUsers.length) {
          this.allUsers = this.allUsers.filter(
            (x) => x && x.id && !addedUsers.includes(x.id)
          );
        }
      }

      this.selectedUser = null;
    },
    getInitials(name) {
      return name
        .split(" ")
        .map((x) => x.substring(0, 1))
        .join("");
    },
    async updateRole() {
      this.show = true;
      const me = this;
      try {
        const upData = {
          role_id: me.roleData.id,
          pid: me.roleData.pid,
          permissions: this.roleData.permissions
        };
        if (me.invitedUsers.length) {
          upData.users = me.invitedUsers.map((x) => x.id);
        }
        let response = await new AccountService().updateRole(upData);
        me.show = false;
        if (response && response.data) {
          me.$toast({
            component: ToastificationContent,
            props: {
              title: me.$t("role.RoleUpdateSuccess"),
              text: me.$t("role.RoleUpdateSuccessMessage"),
              icon: "InfoIcon",
              variant: "success"
            }
          });

          me.$router.push({ name: "user-list", query: { tab: "roles" } });
        } else if (response && response.error && response.error.message) {
          me.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        me.show = false;
      }
    },
    processRolePermissions() {
      let rolePerms = this.roleDataClone.role.permissions;
      let allPermissions = this.roleDataClone.permissions;
      Object.keys(rolePerms).forEach((permKey) => {
        rolePerms[permKey].forEach((action) => {
          if (allPermissions[permKey])
            allPermissions[permKey].op[action].per = true;
        });
      });
    },
    async getRoleData() {
      if (!this.$route.query.id) {
        return;
      }
      let response = await new AccountService().getRoleData({
        role_id: this.$route.query.id
      });

      if (response && response.data) {
        const roleDataClone = response.data && response.data.role;
        this.roleDataClone = response.data;
        this.roleData = {
          permissions: roleDataClone.permissions,
          pid: roleDataClone.pid,
          role_name: roleDataClone.role_name + "copy"
        };
        this.processRolePermissions();
      } else if (response && response.error && response.error.message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error.title,
            text: response.error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },

    async getAllPermissions() {
      this.show = true;
      try {
        let response = await new AccountService().getAllPermissions({
          role_id: this.roleData.pid
        });
        this.show = false;
        if (response && response.data) {
          this.allPermissions = response.data.permissions;

          this.labels = response.data.labels;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response && response.error ? response.error.title : "",
              text: response && response.error ? response.error.message : "",
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.unitRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    async validateRolePermission() {
      const rolePerms = this.parseRoles();
      const perms = Object.keys(rolePerms);
      if (perms.length) {
        this.roleData.permissions = rolePerms;
        this.$forceUpdate();
        return await this.addRole();
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("role.PermissionsErrorTitle"),
            text: this.$t("role.PermissionsError"),
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        return false;
      }
    },
    async addRole() {
      const reqData = this.roleData;
      this.show = true;
      try {
        let response = await new AccountService().addRole(reqData);
        this.show = false;
        if (response && response.data) {
          this.roleData.id = response.data.id;
          this.isAddRole = true;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("roles_management.SaveSuccess"),
              icon: "exclamation-circle-fill",
              variant: "success"
            }
          });
          return true;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response && response.error ? response.error.title : "",
              text: response && response.error ? response.error.message : "",
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          return false;
        }
      } catch (err) {
        this.show = false;
        return false;
      }
    },
    async gotoRoleList() {
      if (this.invitedUsers.length) {
        await this.updateRole();
      } else {
        this.$router.push({ name: "user-list", query: { tab: "roles" } });
      }
    },
    parseRoles() {
      let rolePerms = {};
      const allPermissions =
        (this.roleDataClone && this.roleDataClone.permissions) ||
        this.allPermissions;
      Object.keys(allPermissions).forEach((permKey) => {
        Object.keys(allPermissions[permKey].op).forEach((action) => {
          if (allPermissions[permKey].op[action].per) {
            if (!rolePerms[permKey]) {
              rolePerms[permKey] = [];
            }
            rolePerms[permKey].push(action);
          }
        });
      });
      return rolePerms;
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";

.you-invite {
  font-size: 14px;
  line-height: 21px;
  // text-align: center;
  color: $body-color;
  margin-top: 14px;
}
.add-email {
  display: flex;
  align-items: flex-end;
  .form-group {
    width: calc(100% - 37px);
  }
  .rounded-circle.btn-primary {
    margin-bottom: 14px;
    margin-left: $px_10;
  }
}
.form-group .is-valid .vs__dropdown-toggle {
  .vs__actions {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328c76f' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem);
    min-width: 46px;
    justify-content: flex-end;
    position: relative;
    background-position: 4px 9px;
    margin-left: 0px !important;
  }
}
.form-group .is-valid .vs__dropdown-toggle {
  .vs__clear {
    opacity: 0;
    pointer-events: none;
  }
  .vs__search {
    width: calc(100% - 10px);
  }
}
.dark-layout {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgb(41 48 70) inset !important;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: #b4b7bd !important;
  }
  .form-control.is-valid {
    border-color: #2b9a5d !important;
  }
  .form-group .is-valid .vs__dropdown-toggle {
    border-color: #2b9a5d !important;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(252, 252, 252) inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #babac4 !important;
}
.form-control.is-valid {
  border-color: var(--success) !important;
}
.form-group .is-valid .vs__dropdown-toggle {
  border-color: var(--success) !important;
}
.Add-circle {
  max-height: 36px;
  max-width: 36px;
  min-height: 36px;
  min-width: 36px;
  svg {
    position: inherit;
  }
}
.user-mt-15 {
  margin-top: 15px;
}
.role-padding-right {
  padding-right: 60px;
  display: inline-block;
}
.dark-layout {
  .you-c {
    color: #b4b7bd !important  ;
  }
}
@media only screen and (max-width: 767px) {
  .role-create-page .wizard-tab-content {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
</style>
<style lang="scss" scoped>
.role-create-page {
  .wizard-tab-container {
    height: calc(100vh - 350px) !important;
    .permissions-list {
      overflow-y: auto;
      height: calc(100vh - 346px);
    }
  }
}
.role-scroll {
  height: calc(100vh - 500px);
  overflow-y: auto;
}
</style>
<style lang="scss">
.role-list-input.vs--open {
  .vs__selected-options .vs__selected {
    opacity: 0 !important; /* Set initial opacity */
  }
}
</style>
